import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import PageHeader from '~components/PageHeader'
import RenderModules from '~components/RenderModules'
import Collection from '~components/Collection'
import Tile from '~components/Tile'
import styled from '@emotion/styled'
import Section from '~components/Section'
import RichText from '~components/RichText'
import { mobile, tablet } from '~styles/global'
import FeedbackForm from '~components/FeedbackForm'

const Post = ({ data }) => {

	const page = data.sanityArticle ?? data.sanityStory ?? data.sanityVolunteerStory
	const related = page?.relatedStories ?? page?.relatedArticles ?? page?.relatedVolunteerStories

	const mainRef = useRef(null)
	const sidebarRef = useRef(null)
	const [sidebar, setSidebar] = useState(true)
	const [main, setMain] = useState(false)

	useEffect(() => {
		const sidebarHeight = sidebarRef.current?.offsetHeight
		const mainHeight = mainRef.current?.offsetHeight
		if (sidebarHeight > mainHeight + 100) {
			setSidebar(false)
		}
		setMain(true)
	}, [])

	console.log({related})

	return(
		<>
			<Seo 
				title={page?.title}
				metaTitle={page?.seo?.metaTitle}
				description={page?.seo?.metaDescription}
				image={page?.seo?.socialImage}
			/>
			<PageHeader content={page?.header} page={page}/>
			<Main main={main}>
				<Feature>
					<div ref={mainRef}>
						<RichText moduleStyles content={page?.content}/>
					</div>
				</Feature>
				{(page?.sidebarPosts.length > 0 && sidebar) &&
					<Sidebar ref={sidebarRef} >
						<SidebarHeading>{page?.sidebarTitle}</SidebarHeading>
						{page?.sidebarPosts?.map(post => (
							<RelatedPost key={post.module} hideText content={post}/>
						))}
					</Sidebar>
				}
			</Main>
			<RenderModules modules={page?.modules} />
			{related?.length > 0 &&
				<Collection content={{
					title: page?._type === 'article' ? 'Related Articles' : page?._type === 'story' ? 'More Stories' : 'More Volunteer Profiles',
					posts: related,
					_type: page?._type === 'article' ? 'articleCollection' : page?._type === 'story' ? 'storyCollection' : 'volunteerStoryCollection',
					population: 'select',
					storyType: page?._type,
					background: {
						value: '#F3F1F0'
					}
				}} />
			}
			{page?.sidebarPosts.length > 0 &&
				<Mobile>
					<SidebarMob>
						<SidebarHeading>{page?.sidebarTitle}</SidebarHeading>
						{page?.sidebarPosts?.map(post => (
							<RelatedPost key={post.module} hideText content={post}/>
						))}
					</SidebarMob>	
				</Mobile> 
			}
			{page?.showFeedbackForm &&
				<FeedbackForm />
			}
			<DataViewer data={page} name="page" />
		</>
	)
}

const Main = styled(Section)`
	opacity: ${props => props.main ? 1 : 0};
	padding: var(--xxl) 0 0;
	margin-bottom: var(--l);
	${tablet}{
		padding: var(--xxl) 0 0;
	}
`
const Feature = styled.div`
	grid-column: span 8;
	max-width: 800px;
	${tablet}{
		grid-column: span 12;
	}
`
const Sidebar = styled.div`
	grid-column: 9/13;
	max-width: 393px;
	margin-left: auto;
	${tablet}{
		display: none;
	}
`
const SidebarMob = styled(Sidebar)`
	display: none;
	${tablet}{
		display: grid;
		max-width: none;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 30px;
		grid-column: span 12;
		margin-left: 0;
		margin-top: var(--xl);
		margin-bottom: var(--xl)
	}
	${mobile}{
		grid-column-gap: 14px;
	}
`
const SidebarHeading = styled.h4`
	text-align: center;
	margin-bottom: var(--l);
	${tablet}{
		grid-column: span 2;
	}
`
const RelatedPost = styled(Tile)`
	margin-bottom: var(--xl);
	:last-of-type{
		margin-bottom: 0;
	}
	${mobile}{
		grid-column: span 2;
	}
`
const Mobile = styled(Section)`
	display: none;
	${tablet}{
		display: block;
	}
`

Post.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`	
  query PostQuery($slug: String, $isArticle: Boolean = false, $isStory: Boolean = false,  $isVolunteerStory: Boolean = false) {
    sanityArticle(slug: {current: {eq: $slug}}) @include(if: $isArticle) {
      title
			_type
			datePublished(formatString: "D MMMM YYYY")
			header {
				...pageHeader
			}
			content: _rawContent(resolveReferences: { maxDepth: 10})
			sidebarTitle
			sidebarPosts{
				... on SanityArticle {
					...articleThumb
				}
				... on SanityStory {
					...storyThumb
				}
			}	
			modules {
				... on SanityArticleCollection {
					...articleCollection
				}
				... on SanityStoryCollection {
					...storyCollection
				}
				... on SanityProductCollection {
					...productCollection
				}
				... on SanitySignsSymptoms {
					...signsSymptoms
				}
				... on SanityHelpLine {
					_key
					_type
					include
				}
				... on SanityChatBotModule {
					_key
					_type
					include
				}
				... on SanityChecklist {
					_key
					_type
				}
				... on SanityQuickLinks {
					...quickLinks
				}
				... on SanitySmartSearch {
					...smartSearch
				}
				... on SanityFaqBlock {
					...faqBlock
				}
				... on SanityTextImage {
					...textImage
				}
				... on SanityFreeText {
					...freeText
				}
				... on SanityCenteredText {
					...centeredText
				}
				... on SanityFileCollection {
					...fileCollection
				}
				... on SanityTeam {
					...team
				}
				... on SanityLogoGrid {
					...logoGrid
				}
				... on SanityVideo {
					...video
				}
				... on SanityPodcast {
					...podcast
				}
				... on SanityFormAssemblyEmbed {
					...formAssemblyEmbed
				}
				... on SanityTextColumns {
					...textColumns
				}
				... on SanityQuote {
					...quote
				}
				... on SanityCampaign {
					...campaign
				}
			}
			relatedArticles {
				...articleThumb
			}
			showFeedbackForm
      seo{
				...seo
			}
    }
		# Query for the story
		sanityStory(slug: {current: {eq: $slug}}) @include(if: $isStory) {
			title
			_type
			datePublished(formatString: "D MMMM YYYY")
			header {
				...pageHeader
			}
			content: _rawContent(resolveReferences: { maxDepth: 10})
			sidebarTitle
			sidebarPosts{
				... on SanityArticle {
					...articleThumb
				}
				... on SanityStory {
					...storyThumb
				}
			}
			
			modules {
				... on SanityArticleCollection {
					...articleCollection
				}
				... on SanityStoryCollection {
					...storyCollection
				}
				... on SanitySignsSymptoms {
					...signsSymptoms
				}
				... on SanityHelpLine {
					_key
					_type
					include
				}
				... on SanityChatBotModule {
					_key
					_type
					include
				}
				... on SanityChecklist {
					_key
					_type
				}
				... on SanityQuickLinks {
					...quickLinks
				}
				... on SanitySmartSearch {
					...smartSearch
				}
				... on SanityFaqBlock {
					...faqBlock
				}
				... on SanityTextImage {
					...textImage
				}
				... on SanityFreeText {
					...freeText
				}
				... on SanityCenteredText {
					...centeredText
				}
				... on SanityFileCollection {
					...fileCollection
				}
				... on SanityTeam {
					...team
				}
				... on SanityLogoGrid {
					...logoGrid
				}
				... on SanityContactForm {
					...contactForm
				}
				... on SanityQuote {
					...quote
				}
				... on SanityEmbed {
					_key
					_type
					embedCode
				}
			}
			relatedStories {
				...storyThumb
			}
      seo{
				...seo
			}
    }
		sanityVolunteerStory(slug: {current: {eq: $slug}}) @include(if: $isVolunteerStory) {
			title
			_type
			datePublished(formatString: "D MMMM YYYY")
			header {
				...pageHeader
			}
			content: _rawContent(resolveReferences: { maxDepth: 10})
			sidebarTitle
			sidebarPosts{
				... on SanityArticle {
					...articleThumb
				}
				... on SanityStory {
					...storyThumb
				}
			}
			modules {
				... on SanityArticleCollection {
					...articleCollection
				}
				... on SanityStoryCollection {
					...storyCollection
				}
				... on SanitySignsSymptoms {
					...signsSymptoms
				}
				... on SanityHelpLine {
					_key
					_type
					include
				}
				... on SanityChatBotModule {
					_key
					_type
					include
				}
				... on SanityChecklist {
					_key
					_type
				}
				... on SanityQuickLinks {
					...quickLinks
				}
				... on SanitySmartSearch {
					...smartSearch
				}
				... on SanityFaqBlock {
					...faqBlock
				}
				... on SanityTextImage {
					...textImage
				}
				... on SanityFreeText {
					...freeText
				}
				... on SanityCenteredText {
					...centeredText
				}
				... on SanityFileCollection {
					...fileCollection
				}
				... on SanityTeam {
					...team
				}
				... on SanityLogoGrid {
					...logoGrid
				}
				... on SanityContactForm {
					...contactForm
				}
				... on SanityQuote {
					...quote
				}
				... on SanityEmbed {
					_key
					_type
					embedCode
				}
			}
			relatedVolunteerStories {
				...volunteerStoryThumb
			}
      seo{
				...seo
			}
		}
  }
`

export default Post